import React from 'react'
import Layout from './layout.js'

import { MDXRenderer } from 'gatsby-plugin-mdx'

import '../styles/styles.css';
import '../styles/page.css';
import PDF from './pdf.js';
import SEO from './seo.js';
import moment from 'moment';

const OnePagerLayout = (props) => {
    const content = props.pageContext.Content && props.pageContext.Content.childMdx.body;
    const attachments = props.pageContext.Attachments && props.pageContext.Attachments.localFiles;
    let leadingImage;
    if (attachments) {
        // if the first item is an image, show it at the top 
        // and then shift() it out of the attachments array 
        if (["jpg","jpeg","png","gif"].includes(attachments[0].extension)) {
            leadingImage = attachments.shift();
        }
    }
    let imageProps = {};
    if (props.pageContext.ogImage) {
        imageProps.imagePath = props.pageContext.ogImage.path;
        imageProps.imageWidth = props.pageContext.ogImage.size.width;
        imageProps.imageHeight = props.pageContext.ogImage.size.height;
    }
    if (leadingImage) {
        imageProps.imagePath = leadingImage.publicURL;
        imageProps.imageWidth = leadingImage.childImageSharp.original.width;
        imageProps.imageHeight = leadingImage.childImageSharp.original.height;
    }
    return (
        <Layout>
            <SEO
                title={props.pageContext.Title}
                {...imageProps}
            />
            <div className="page-container">
                {
                    leadingImage && 
                    <div>
                        <img
                            alt=""
                            style={{ width: '100%' }} 
                            src={leadingImage.publicURL} />
                    </div>             
                }
                {
                    content && 
                    <MDXRenderer>{content}</MDXRenderer>
                }
                {
                    attachments && 
                    attachments.map((attachment, index) => {
                        switch(attachment.extension) {
                            case "pdf":
                                return <div>
                                    <a
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        href={attachment.publicURL}>Download {attachment.name} ({attachment.prettySize})
                                    </a>
                                    <PDF key={index} src={attachment.publicURL} />
                                </div>
                            default:
                                return <div>
                                <a
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    href={attachment.publicURL}>Download {attachment.name} ({attachment.prettySize})
                                </a>
                                </div>
                        }
                    })
                }
                {
                    props.pageContext['last_modified_time'] && 
                    <div><em>
                        Page last updated: 
                    {
                        moment(
                            props.pageContext['last_modified_time']
                        ).format("MMM Do YYYY")
                    }
                    </em></div>
                }
            </div>
        </Layout>
    )
}

export default OnePagerLayout