import React from "react";

import '../styles/styles.css';

const PDF = (props) => {
  return (
    <iframe {...props} title="pdf-viewer" className="pdf-viewer" frameBorder="0"></iframe>
  );
}

export default PDF;